import React from 'react';
import { Link } from 'react-router-dom';
import HeaderIntro from '../layout/header-intro';
import dashboard from '../../images/demo/dashboard.png'

const Intro = () => {
    return (
        <>
            <HeaderIntro />
            <div className="intro" id="intro" data-scroll-index="0">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-6 col-md-6">
                            <div className="intro-content pb-5">
                                <h1>Kieren - Nextgen legal assistant</h1>
                                <p>Kieren is the next generation legal AI to help you process, store legal document.</p>
                                <div className="intro-btn">
                                    <Link to={"/signin"} className="btn btn-primary">Get Started</Link>
                                    <Link to={"/"} className="btn btn-outline-primary">Browse Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-6 py-md-5">
                            <div className="intro-demo_img">
                                <img src={dashboard} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Intro;