import 'react-perfect-scrollbar/dist/css/styles.css';
import '../src/css/style.css'
import Router from './jsx/router'

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
